.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p{
  margin: 0;
}
.top{
  width: 100%;
  text-align: center;
  background-color: rgb(255, 129, 129);
  margin: 0;
  font-size: 30px;
  display: flex;
  justify-content: space-around;
  height: 50px;
  position: fixed;
}
body{
  margin: 0;
  /* background-image: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIALcAxAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQIG/8QAJRABAQACAwAABgIDAAAAAAAAAAERMQIhQRIiUWFxgTLBgpGx/8QAGAEBAQADAAAAAAAAAAAAAAAAAAECBQf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAwDAQACEQMRAD8A5yXtbtnje2rb41Tq6Hfuk/Kglx5oi3aABSIAAAAgAAACXZNqKq3+mbVt/wCEBFmlszPwkuYCXazQl2iKJgBVx0mDCqYIGQS7WQEBLtT0CaEu1mgEu1BCaABLsUAmhLsFUwizQAAgAAAAqHfgqofkAyeB6CLFRRay3x9Y9AWaEu0RTCLKKlWaAAAQws48r1xmftbIzYYBu8Oc6vDFZ1uYTuXpfi5KAb2AAIL4i/HbPmnG36/D3/tN+YVaAIAGADPYuFDu6Zu+2+PrF2CzQizSIAAAAJdqATQAAACXagE0ABm+GfqCqAIAACXanqhNCzHeUmugAEAAQEu1mgD1LtZoUx2l2qXYLNBNJdiKE0AAACZWCpdrNFMACVZoFx0gYAMHhAEu2rtAJoAQMBkUAAAEAAS7WaAAADPw/sUwqoY7MJoC7WaMiAAAJdrNAGOz5f8AIlUS7I1xmc3Gf6+6a9yBWWj1EJpLtaQCaAFAAABAAFkx2l5ZqphVJMxZv+OU8IC8p39PyzS7WaAmgAMgAuF8Yu1k6FS7WaVMdjEEuxFVLtZoAmgS7BSk0AQDCgM8ufw3H0URaRbtBTIVMoKEAAAMLOKHqi3pM9KXYJ4TYZufl/YLZM/N+kuZtevDOegSBZjogAGUQCAJdipdgs0E0AZJnxfk+HraYVVxypetpnACZJc3DWZ6XHLqfDn7gl44omLx6u/ss0AegC5wt7mWQEIvp6Cy9/xyWZusM3azQKgAAl2guEos0ITQAAAF7RbomlVFmjE9MfRBLtZ3MZz9jOEoLZjrGCJFwACXYKl2LNASCXazQJdrIKogctk0AUAIl2oBNKzdrKgqACS33S9Z6BQysqALe0AABAS7UBFmgAygAs0l2oovD1AAAAS7UAkAAAQf/9k="); */
  background-size: cover;
}
h1{
  margin: 0;
}
li{
  list-style: none;
  width: 100%;
  background-color: rgb(255, 129, 129);
  /* height: 100px; */
}
.menu{
  position: absolute;
  margin: auto;
}
.label {
  display: flex;
  justify-content: space-around;
}
.summary1{
  list-style: none;
  margin: 10px;
  background-color: rgb(255, 129, 129);
  color: rgb(0, 0, 0);
  margin: 0;
  height: 50px;
}
.summary{
  list-style: none;
  margin: 10px;
  margin: 0;
  height: 50px;
}
.label_li{
  text-align: center;
  /* z-index: 1000; */
  position: absolute;
  width: 100%;
  margin: 0;
}
.label_li3{
  text-align: center;
  /* z-index: 1000; */
  /* position: absolute; */
  width: 100%;
  margin: 0;
  height: 60px;
}
.label_li1{
  display: none;
}
a{
  width: 100%;
  text-decoration:none;
  color: rgb(0, 0, 0);
  height: 60px;
}
details{
  width: 100%;
  height: 100%;
}
details[open] {
  background-color: rgb(255, 129, 129);
}
p{
  width: 100%;
  height: 100%;
  margin-top: 7px;
}
.aaa{
  width: 100%;
  height: 100%;
  margin-top: 7px;
  color: black;
}
.label_li2{
  position: absolute;
  text-align: center;
  width: 32.4%;
}
.menu-btn {
  /* position: fixed; */
  /* top: 10px; */
  /* right: 10px; */
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  z-index: 90;
  background-color: #3584bb;
  margin: 0;
}
.menu-btn span,
.menu-btn span:before,
.menu-btn span:after {
  content: '';
  display: block;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  background-color: #ffffff;
  position: absolute;
}
.menu-btn span:before {
  bottom: 8px;
}
.menu-btn span:after {
  top: 8px;
}
#menu-btn-check:checked ~ .menu-btn span {
  background-color: rgba(255, 255, 255, 0);/*メニューオープン時は真ん中の線を透明にする*/
}
#menu-btn-check:checked ~ .menu-btn span::before {
  bottom: 0;
  transform: rotate(45deg);
}
#menu-btn-check:checked ~ .menu-btn span::after {
  top: 0;
  transform: rotate(-45deg);
}
#menu-btn-check {
  display: none;
}
.detail{
  background-color: white;
}
h5{
  margin: 0;
}
.summ{
  color: black;
}

.img_a{
  width: 100%;
}